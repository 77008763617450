<template>
  <div id="addGender" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >RELACIÓN ENTRE ÁREAS Y PUESTOS DE TRABAJO</v-row
      >
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol job">
            <v-row>
              <div class="form-group mb-2">
                <label for="empresa">Empresa</label>
                <select
                  name="empresa"
                  id="empresa"
                  v-model="enterprise"
                  @change="obtenerAreasTrabajos()"
                >
                  <option disabled selected value="0">Selecciona</option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row >
              <p style="font-size: 18px">
                <i style="color: #0f6c21" class="fas fa-file-excel"></i>
                <a href="#" @click="descargaExcel()">&nbsp;&nbsp;Descargar</a>
                plantilla de excel para llenar.
              </p>
                <div style="background:#c4c1c1;border-radius: 20px;
    margin-top: 20px;
    text-align: center">
              Subir archivo Excel con la relación de areás-puestos
              <input
                type="file"
                id="archivo"
                class="pb-2"
                lang="es"
                ref="fileUploadPerfilEdit"
                @click="resetFile"
                @change="previewFiles($event)"
              />
                </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <router-link to="/connection" id="backConnection">
                <button class="botonAmarilloDerecho" @click="regresar()">
                  Regresar
                </button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{titulo}}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la relacion de puestos con las áreas
            asignadas?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              cancel();
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="procesarExcel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{titulo}}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      expiration: false,
      show: false,
      dialog: false,
      errors: [],
      respuesta: "",
      titulo:"",
      advice: false,
      error: false,
      confirmation: false,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
    };
  },
  methods: {
    descargaExcel() {
      this.show = true;
      if (this.enterprise != 0) {
        this.show = true;
        axios(
          {
            url: Server + "/puestos/plantilla-puestos-areas/" + this.enterprise,
            method: "GET",
            responseType: "blob",
          },
          { useCredentails: true }
        ) // CORS
          .then((response) => {
            this.show = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            var fecha = new Date();
            link.setAttribute(
              "download",
              "Puestos" + fecha.getTime() + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((e) => {
            this.show = false;
          });
      } else {
        this.errors.push("Por favor selecciona la empresa Pagadora");
        this.error = true;
      }
    },
    validacion() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.errors.push("Debe especificar la empresa pagadora")
        this.error = true;;
      }
      if (this.errors.length == 0) {
        this.advice = true;
      }
    },
    cancel() {
      this.advice = false;
      this.enterprise = 0;
    },
    aux() {
      this.confirmation = false;
      this.$router.push("/connection");
    },
    regresar() {
      this.enterprise = 0;
      this.$router.push("/connection");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    resetFile() {
      this.$refs.fileUploadPerfilEdit.value = "";
    },
    procesarExcel: function () {
      this.advice = false;
      var data = new FormData();
      data.append("idEmpresa", this.enterprise);
      data.append("excel", this.selectedFile, this.selectedFile.name);
      this.show = true;
      axios
        .post(Server + "/puestos/cargar/excel-puestos-areas", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.titulo = "Confirmación";
          this.respuesta = "La relación fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          this.respuesta = "La relación no fue guardada.";
          this.confirmation = true;
        });
    },
    previewFiles(event) {
      this.errors = [];
      //console.log(event);
      this.nombreArchivo = event.target.files[0].name;
      this.nombreArchivo.toLowerCase();
      var regex = new RegExp("(.*?).(xlsx|xls)$");
      if (!regex.test(this.nombreArchivo)) {
        this.nombreArchivo = "";
        this.titulo = "Archivo inválido";
        this.errors.push(
          "No se permite el archivo que ingreso favor de ingresar archivo con layout predeterminado."
        );
        this.error = true;
      } else {
        this.selectedFile = event.target.files[0];
        //console.log("listo", this.selectedFile);
      }
    },
  },
  created() {
    this.listar();
  },
};
</script>
